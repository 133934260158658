import React from "react";
import { Route, withRouter } from "react-router-dom";
import IndexPage from "../pages/Index";
import LoginContext from "../context/login";
import Login from "../pages/login/Index";

const Middleware = (props) => {
  return (
    <LoginContext>
      <Route path="/" component={IndexPage} />
    </LoginContext>
  );
};

export default withRouter(Middleware);
