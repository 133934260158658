import axios from "axios";
import { axiosGenerate, GetToken } from "./axios";
import { baseUrl, path_users, urlLPJK } from "./configUrl";

export const GetListData = async (strUrl) => {
  let newToken = await GetToken();
  // console.log("new Token", newToken);
  return new Promise((resolve, reject) => {
    const config = {
      method: "get",
      url: baseUrl + strUrl,
      headers: {
        "Content-Type": "aplication/json",
        Authorization: "Bearer" + " " + newToken.secret_key,
      },
    };
    axios(config)
      .then((res) => {
        if (res.data.responseStatus === "SUCCESS") {
          resolve(res.data);

          return;
        }

        reject(res.data);
      })
      .catch((e) => {
        reject(e.response);
      });
  });
};

export const insertFormData = async (strUrl, body) => {
  let newToken = await GetToken();
  return new Promise((resolve, reject) => {
    const config = {
      method: "post",
      url: baseUrl + strUrl,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${newToken.secret_key}`,
        "Content-Type": "multipart/form-data",
      },

      data: body,
    };
    axios(config)
      .then((res) => {
        if (res.data.responseStatus === "SUCCESS") {
          resolve(res.data);
          return;
        }

        reject(res.data);
      })
      .catch((e) => {
        reject(e.response);
      });
  });
};

export const insertData = async (strUrl, body) => {
  let newToken = await GetToken();
  return new Promise((resolve, reject) => {
    // console.log("massukkk");
    const config = {
      method: "post",
      url: baseUrl + strUrl,
      headers: {
        "Content-Type": "aplication/json",
        Authorization: `Bearer ${newToken.secret_key}`,
      },
      data: body,
    };
    axios(config)
      .then((res) => {
        if (res.data.responseStatus === "SUCCESS") {
          resolve(res.data);
          return;
        }

        reject(res.data);
      })
      .catch((e) => {
        reject(e.response);
      });
  });
};

export const postLoginIntegration = async (config) => {
  return new Promise((resolve, reject) => {
    axios(config)
      .then((res) => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        reject(res.data);
      })
      .catch((e) => {
        reject(e.response);
      });
  });
}

export const postIntegration = async (strUrl, body) => {
  return new Promise((resolve, reject) => {
    const config = {
      method: "post",
      url: strUrl,
      headers: {
        "Content-Type": "aplication/json",
        Accept: "aplication/json",
      },
      data: body,
    };
    axios(config)
      .then((res) => {
        if (res.status === 200) {
          resolve(res.data);

          return;
        }
        reject(res.data);
      })
      .catch((e) => {
        reject(e.response);
      });
  });
};

export const EditData = async (strUrl, body) => {
  let newToken = await GetToken();
  return new Promise((resolve, reject) => {
    axios({
      method: "PUT",
      url: baseUrl + strUrl,
      headers: {
        "Content-Type": "aplication/json",
        Authorization: `Bearer ${newToken.secret_key}`,
      },
      data: body,
    })
      .then((res) => {
        if (res.data.responseStatus === "SUCCESS") {
          resolve(res.data);
          return;
        }

        reject(res.data);
      })
      .catch((e) => {
        reject(e.response);
      });
  });
};
export const EditDataNew = async (strUrl, body) => {
  let newToken = await GetToken();
  return new Promise((resolve, reject) => {
    axios({
      method: "PUT",
      url: `${baseUrl}${strUrl}`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${newToken.secret_key}`,
        "Content-Type": "application/json",
      },
      data: JSON.stringify(body),
    })
      .then((res) => {
        if (res.data.responseStatus === "SUCCESS") {
          resolve(res.data);
          return;
        }

        reject(res.data);
      })
      .catch((e) => {
        reject(e.response);
      });
  });
};
export const DeleteData = async (strUrl, body) => {
  let newToken = await GetToken();
  return new Promise((resolve, reject) => {
    axios({
      method: "DELETE",
      url: baseUrl + strUrl,
      headers: {
        "Content-Type": "aplication/json",
        Authorization: `Bearer ${newToken.secret_key}`,
      },
      data: body,
    })
      .then((res) => {
        if (res.data.responseStatus === "SUCCESS") {
          resolve(res.data);
          return;
        }

        reject(res.data);
      })
      .catch((e) => {
        reject(e.response);
      });
  });
};

export function PostData(type, data) {
  const FETCH_TIMEOUT = 10000;
  let didTimeOut = false;
  let config = baseUrl + path_users;

  return new Promise((resolve, reject) => {
    const timeout = setTimeout(function () {
      didTimeOut = true;
      reject(new Error("Request Time Out"));
    }, FETCH_TIMEOUT);

    fetch(config + type, {
      method: "POST",

      body: JSON.stringify(data),
    });
    // console.log("config", config);
    // console.log("type", type);
    console
      .log("data", data)
      .then((response) => response.json(console.log("respinse", response)))

      .then((responseJson) => {
        // console.log("response", responseJson);
        clearTimeout(timeout);
        if (!didTimeOut) {
          resolve(responseJson);
        }
      })
      .catch((error) => {
        // console.log("err", error);
        if (didTimeOut) return;
        alert("server Offline");
        reject(error);
      });
  }).catch(function (err) {
    // console.log("err cone", err);
    alert("Not Internet Connection");
    // window.location.reload();
  });
}
const setLocalStorage = (responseJSON) => {
  localStorage.setItem("secret_key", responseJSON.secret_key);
};

export const refreshToken = () => {
  let refresh_count = localStorage.getItem('refresh_count');
  if(refresh_count >=3){
    localStorage.clear();
    window.location.replace("/login");
  }else{
    if(!refresh_count){
      refresh_count = 1;
    }
  }
  const data = {};
  const token = localStorage.getItem("secret_key");
  data["token"] = token;

  insertData("/users/refresh_token", data)
    .then((response) => {
      localStorage.setItem("refresh_count", refresh_count+1);
      let responseJSON = response;
      if (response.responseStatus === "SUCCESS") {
        setLocalStorage(responseJSON);
        window.location.reload();
      }
    })
    .catch((error) => {
      // const dataMessage = error.data.description;
      // if (error.status === 500) {
      localStorage.clear();
      window.location.replace("/login");
        // if (dataMessage.includes("revoke")) {
        //   localStorage.clear();
        //   window.location.replace("/login");
        // } else {
        //   console.log(dataMessage);
        // }
      // }
    });
};
