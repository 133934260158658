export function formatCapitalize(value) {
  var text = value;
  if (value !== undefined) {
    text = text
      .toLowerCase()
      .split(" ")
      .map((item) => item.charAt(0).toUpperCase() + item.substring(1))
      .join(" ");
  } else {
    text = value;
  }

  return text;
}

export function Capital(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function SecondCapital(string) {
  return string.charAt(1).toUpperCase() + string.slice(2);
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function handleBreadCrumb(string){
  let arrStr = string.split('/');
  let stringFirst = arrStr[1];
  return capitalizeFirstLetter(stringFirst);
}

const escapeRegExp = (string) => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

export const replaceAll = (str, find, replace) => {
  return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
}