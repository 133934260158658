import React from "react";
import { withRouter, Switch, Route, BrowserRouter } from "react-router-dom";
import LoginPage from "./pages/login/Index";
import "font-awesome/css/font-awesome.min.css";
import Middlewares from "./middlewares";
import "./css/style.css";

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/login" component={LoginPage} />
        <Middlewares />
      </Switch>
    </BrowserRouter>
  );
}

export default withRouter(App);
