import React, { createContext, useState } from "react";
import { withRouter } from "react-router-dom";
import { insertData } from "../config/action";
export const LoginContext = createContext();

function LoginContextContainer(props) {
  const [login, setLogin] = useState(false);
  const [auth, setAuth] = useState(true);
  const [username_email, setUsername_Email] = useState("");
  const [password, setPassword] = useState("");
  const [hidden, setHidden] = useState(true);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [logged_in, setLogged_in] = useState(false);

  // const handleOnValidation = (event) => {
  //   event.preventDefault();
  //   if (!username_email) {
  //     // setLoading(false);
  //     setHidden(false);
  //     setMessage("Username and password cannot empty");
  //   }
  //   if (!password) {
  //     // setLoading(false);
  //     setHidden(false);
  //     setMessage("Minimum password length is 6");
  //     return false;
  //   }

  //   handleOnSubmitBtnLogin(event);
  // };

  const handleLocalStorage = (responseJSON) => {
    let dataUser = responseJSON.data;

    let role = "";

    if(dataUser.role_code){
      // localStorage.setItem("role", dataUser.role_code);
      role = dataUser.role_code
    }else{
      if(dataUser.user_permissions && dataUser.user_permissions.length > 0){
        role = dataUser.user_permissions[0].role_code;
        // localStorage.setItem("role", dataUser.user_permissions[0].role_code);
      }
    }

    if (role!='NAS') {
      localStorage.clear();
      setHidden(false);
      setMessage("Anda tidak memiliki akses, silahkan cek kembali!");
      return;
    }
    // console.log('responseJSON', responseJSON);
    setLogged_in(true);
    localStorage.setItem("userdata", JSON.stringify(dataUser));
    localStorage.setItem("secret_key", responseJSON.secret_key);
    localStorage.setItem("logged_in", dataUser.logged_in);
    localStorage.setItem("identity_type", responseJSON.identity_type);
    
    localStorage.setItem("bahasa", "id");
    localStorage.setItem("user_permission_id", responseJSON.data.user_permission_id);

    localStorage.setItem("role", role);
    // return;
    props.history.push("/dashboard");
    window.location.reload();
  };

  const handleOnSubmitBtnLogin = (event) => {
    // event.preventDefault();

    setLoading(true);

    if (!username_email) {
      // setLoading(false);
      setHidden(false);
      setMessage("Username and password cannot empty");
    }
    if (!password) {
      // setLoading(false);
      setHidden(false);
      setMessage("Minimum password length is 6");
      return false;
    } else {
      insertData("/users/login", {
        password: password,
        username_email: username_email,
      })
        .then((result) => {
          setLoading(false);
          let responseJSON = result;
          if (result.responseStatus === "SUCCESS") {
            handleLocalStorage(responseJSON);
          } else {
            localStorage.clear();
            setHidden(false);
            setMessage("Anda tidak memiliki akses, silahkan cek kembali!");
          }
          return;
        })

        .catch((e) => {
          setLoading(false);

          if (e.status === 400) {
            setHidden(false);
            setMessage("Minimum password length is 6");
          }

          if (e.status === 422) {
            setHidden(false);
            setMessage("Wrong password. Try again");
          }
        });
    }
  };

  // const handleSubmitLsp = (body) => {
  //   setLoading(true);
  //   insertData("/users/select_lsp", {
  //     user_permission_id: body,
  //   })
  //     .then((result) => {
  //       setLoading(false);
  //       let responseJSON = result;
  //       if (result.responseStatus === "SUCCESS") {
  //         handleLocalStorage(responseJSON);
  //         props.history.push("/dashboard");
  //         window.location.reload();
  //       }
  //     })

  //     .catch((e) => {
  //       setLoading(false);
  //       if (e.status === 401) {
  //         localStorage.clear();
  //         props.history.push("/login");
  //       }
  //       if (e.status === 409) {
  //         setHidden(false);
  //         setMessage(e.data.error.message);
  //       }
  //       if (e.status === 400) {
  //         setHidden(false);
  //         setMessage(e.data.error.message);
  //       }

  //       if (e.status === 500) {
  //         setHidden(false);
  //         setMessage("Terjadi Kesalahan silahkan Coba lagi");
  //       }
  //     });
  // };
  const handleChangeName = (event) => {
    setUsername_Email(event.target.value);
  };
  const handleChangePassword = (event) => {
    setPassword(event.target.value);
  };
  return (
    <LoginContext.Provider
      value={{
        handleOnSubmitBtnLogin,
        login,
        handleChangeName,
        handleChangePassword,
        // handleOnValidation,
        hidden,
        message,
        loading,
        logged_in,
        setUsername_Email,
      }}
    >
      {props.children}
    </LoginContext.Provider>
  );
}

export default withRouter(LoginContextContainer);
