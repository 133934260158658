import React, { Suspense, useContext, useState, useEffect } from "react";
import { Link, Switch, Route, Redirect } from "react-router-dom";
import menus from "../config/Menus.json";
import {
  Layout,
  Menu,
  Avatar,
  Breadcrumb,
  Icon,
  Dropdown,
  message,
  Tooltip,
  Button,
  Image,
  Card,
} from "antd";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarText,
} from "reactstrap";
// import {Card} from 'reactstrap'
import { LoginContext } from "../context/login";
import Title from "antd/lib/typography/Title";
import { handleBreadCrumb, SecondCapital } from "./../config/Helper";

import logo from "../assets/image/nas_landscape_white_font.png";
import profile from "../assets/image/dp.png";
import routes from "../routes";

import {
  AppstoreOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  PieChartOutlined,
  DesktopOutlined,
  ContainerOutlined,
  MailOutlined,
} from "@ant-design/icons";
import { insertData, PostData } from "../config/action";
import { baseUrl } from "../config/configUrl";

const { Header, Footer, Sider, Content } = Layout;

function PageIndex(props) {
  const user = useContext(LoginContext);

  const [collapse, setCollapse] = useState(false);
  const [login, setLogin] = useState(false);
  const [hidden, setHidden] = useState(true);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [matches, setMatches] = useState(
    window.matchMedia("(max-width:  768px)").matches
  );

  const handleToggle = (event) => {
    event.preventDefault();
    collapse ? setCollapse(false) : setCollapse(true);
  };

  useEffect(() => {
    if (matches) {
      setCollapse(true);
    } else {
      setCollapse(false);
    }
  }, []);

  const items_role = [
    { href: "/dashboard", name: "Beranda" },
    { href: "/pendaftaran", name: "Pendaftaran LSP" },
    { href: "/profile", name: "Profile LSP" },
    { href: "/tagihan", name: "Tagihan" },
    { href: "/report", name: "Laporan Keluhan Pengguna" },
    { href: "/pengguna", name: "Pengaturan" },
  ];

  let comp = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"];

  const logout = () => {
    setLoading(true);
    const tokenData = localStorage.getItem("secret_key");

    insertData("/users/logout", {
      token: tokenData,
    })
      .then((result) => {
        setLoading(false);

        if (result.responseStatus === "SUCCESS") {
          localStorage.clear();
          setLogin(false);
          props.history.push("/login");
        }
      })

      .catch((e) => {
        setLoading(false);
        if (e.status === 401) {
          localStorage.clear();
          props.history.push("/login");
        }
        if (e.status === 409) {
          setHidden(false);
          setMessage(e.data.error.message);
        }
        if (e.status === 400) {
          setHidden(false);
          setMessage(e.data.error.message);
        }
        if (e.status === 500) {
          setHidden(false);
          setMessage("Terjadi Kesalahan silahkan Coba lagi");
        }
      });
  };
  const userData = JSON.parse(localStorage.getItem("userdata"));

  const rootSubmenuKeys = ["Pengaturan"];
  const [openKeys, setOpenKeys] = React.useState(["Pengaturan"]);
  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };
  const menuProfile = (
    <Menu>
      <Menu.Item disabled>Developer</Menu.Item>
      <Menu.Item>
        <i className="fa fa-lock" onClick={logout}>
          Keluar
        </i>
      </Menu.Item>
    </Menu>
  );

  return (
    <Layout style={{ minHeight: "100vh" }}>
      {localStorage.length === 0 ? <Redirect to={"/login"} /> : null}

      <Header
        className="siteLayoutBackground"
        style={{
          padding: 0,
          background: "linear-gradient(to right,#29a7f2, #0c6ea8)",
          width: "100%",
          zIndex: 9,
          position: "fixed",
        }}
      >
        <div>
          <Navbar>
            <NavbarBrand>
              {" "}
              <Image
                width={120}
                height={60}
                preview={false}
                style={{
                  marginLeft: "auto",
                  marginTop: -10,
                }}
                src={logo}
              />
            </NavbarBrand>

            {comp.map(function (item, i) {
              return <NavbarText />;
            })}

            <NavbarText>
              {/* <a className="btn-setting" role="button">
                <i
                  className="fa fa-bell"
                  style={{
                    color: "#ffffff",
                    float: "right",
                    fontSize: 20,
                    marginBottom: 30,
                  }}
                />
              </a> */}
            </NavbarText>

            <span
              style={{
                color: "#ffffff",
                float: "right",
                marginLeft: "20px",
                marginRight: "-75px",
                fontSize: 20,
                marginBottom: 30,
              }}
            >
              Hai,{userData === null ? null : userData.username}
            </span>
            <NavbarText>
              <Dropdown overlay={menuProfile} placement="bottomRight">
                <Avatar
                  style={{ marginBottom: 30 }}
                  size={40}
                  src={userData === null ? profile : baseUrl + userData.picture}
                />
              </Dropdown>
            </NavbarText>
          </Navbar>
        </div>

        {/* 
        {React.createElement(collapse ? MenuUnfoldOutlined : MenuFoldOutlined, {
          className: "trigger",
          onClick: handleToggle,
          style: { color: "#fff", marginLeft: 100 },
        })} */}
      </Header>

      <Layout style={{ marginTop: 86 }}>
        <Sider
          collapsed={collapse}
          width={240}
          style={{ backgroundColor: "#ffffff" }}
        >
          <Menu
            defaultSelectedKeys={["Dashboard"]}
            openKeys={openKeys}
            mode="inline"
            onOpenChange={onOpenChange}
          >
            {/* <Button
              type="primary"
              onClick={handleToggle}
              style={{ marginBottom: 16 }}
            >
              {React.createElement(
                collapse ? MenuUnfoldOutlined : MenuFoldOutlined
              )}
            </Button> */}
            {menus.map((menu, i) => {
              if (menu.level.indexOf != -1) {
                if (menu.item.length > 0) {
                  return (
                    <Menu.SubMenu
                      className="menu"
                      key={menu.key}
                      title={
                        <span className="textmenu">
                          <i
                            style={
                              matches ? { marginRight: 35 } : { marginRight: 5 }
                            }
                            class={menu.icon}
                          ></i>
                          <span>{menu.name}</span>
                        </span>
                      }
                    >
                      {menu.item.map((submenu, item) => {
                        return (
                          <Menu.Item key={submenu.key} className="menu">
                            <Link to={submenu.href} className="textmenu">
                              <i
                                style={{ marginRight: 5 }}
                                class={menu.icon}
                              ></i>
                              {submenu.name}
                            </Link>
                          </Menu.Item>
                        );
                      })}
                    </Menu.SubMenu>
                  );
                }

                return (
                  <Menu.Item
                    key={menu.key}
                    style={{ marginTop: "15 px" }}
                    className="menu"
                  >
                    <Link to={menu.href} className="textmenu">
                      <i
                        class={menu.icon}
                        style={
                          matches ? { marginRight: 35 } : { marginRight: 5 }
                        }
                      ></i>{" "}
                      <span></span> {menu.name}
                    </Link>
                  </Menu.Item>
                );
              }
            })}
          </Menu>
        </Sider>

        <Layout style={{ padding: "0 16px" }}>
          <Breadcrumb style={{ margin: "15px 0" }}>
            <Breadcrumb.Item>
              {" "}
              <a href="/dashboard">
                <i className="fa fa-home" /> Dashboard
              </a>
            </Breadcrumb.Item>

            <Breadcrumb.Item>
              {handleBreadCrumb(props.history.location.pathname)}
            </Breadcrumb.Item>
            {/* {menus.map((item, i) => {
              console.log("item", item.href);
              console.log("location", props.history.location.pathname);

              // {
              //   item.href === props.history.location.pathname ? (
              //     <Breadcrumb.Item active>{item.name}</Breadcrumb.Item>
              //   ) : (
              //     <Breadcrumb.Item linkProps={{ to: item.href }} linkAs={Link}>
              //       {item.name}
              //     </Breadcrumb.Item>
              //   );
              // }

              return item.href == props.history.location.pathname ? (
                <Breadcrumb.Item active>{item.name}</Breadcrumb.Item> 
              ) : (
                <Breadcrumb.Item linkProps={{ to: item.href }} linkAs={Link}>
                  {item.name}
                </Breadcrumb.Item>
              );
            })} */}
          </Breadcrumb>

          <Content style={{ margin: "0 16px" }}>
            <Suspense fallback={<div>Loading...</div>}>
              <Switch>
                {routes.map((route, idx) => {
                  return route.component ? (
                    <Route
                      key={idx}
                      path={route.path}
                      exact={route.exact}
                      name={route.name}
                      render={(props) => {
                        if (props) {
                          return <route.component {...props} />;
                        }
                      }}
                    />
                  ) : null;
                })}
                <Redirect from="/" to="/dashboard" />
              </Switch>
            </Suspense>
          </Content>
        </Layout>
      </Layout>

      <Card
        style={{
          backgroundColor: "white",
          marginTop: "20px",
        }}
      >
        <Footer
          style={{
            left: 0,
            position: "fixed",
            bottom: 0,
            width: "100%",
            backgroundColor: "white",
            textAlign: "center",
          }}
        >
          {" "}
          <span>
            Powered by{" "}
            <a href="https://www.aplikasisertifikasi.com/" target="_blank">
              NAS
            </a>
          </span>
        </Footer>
      </Card>
    </Layout>
  );
}

export default PageIndex;
