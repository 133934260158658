import React from "react";

const Login = React.lazy(() => import("./pages/login/Index"));
const Home = React.lazy(() => import("./pages/home/Index"));
const Profile = React.lazy(() => import("./pages/profile/Index"));
const DetailProfile = React.lazy(() => import("./pages/profile/Detail"));
const DetailPaket = React.lazy(() => import("./pages/paket/DetailPaket"));

const DetailAksesUser = React.lazy(() => import("./pages/akses-user/Detail"));
const DetailMenu = React.lazy(() => import("./pages/menu/DetailMenu"));
const Pendaftaran = React.lazy(() => import("./pages/pendaftaran/Index"));
const LSP = React.lazy(() => import("./pages/lsp/lsp"));
const LSPCE = React.lazy(() => import("./pages/lsp/lspce/lspce"));
const LettersCategory = React.lazy(() => import("./pages/letters-category/lettersCategory"));
const Paket = React.lazy(() => import("./pages/paket/Index"));
const Role = React.lazy(() => import("./pages/role/Index"));
const Menu = React.lazy(() => import("./pages/menu/Index"));
const AksesMenu = React.lazy(() => import("./pages/akses-menu/Index"));
const AksesUser = React.lazy(() => import("./pages/akses-user/Index"));
const VariableDokument = React.lazy(() =>
  import("./pages/variabel-dokumen/Index")
);
const Tagihan = React.lazy(() => import("./pages/tagihan/Index"));
const Laporan = React.lazy(() => import("./pages/report/Index"));

const TambahLaporan = React.lazy(() => import("./pages/report/Add"));
const DetailLaporan = React.lazy(() => import("./pages/report/Detail"));
// const TambahVariable = React.lazy(() => import("./pages/variabel-dokumen/Add"));
const EditMenu = React.lazy(() => import("./pages/menu/EditMenu"));

const EditPaket = React.lazy(() => import("./pages/paket/EditPaket"));
const AddAksesUser = React.lazy(() => import("./pages/akses-user/Add"));
const EditAksesUser = React.lazy(() => import("./pages/akses-user/Edit"));

const routes = [
  { path: "/login", exact: true, name: "Login", component: Login },
  { path: "/dashboard", exact: true, name: "Home", component: Home },
  // { path: "/", exact: true, name: "Home", component: Home },
  { path: "/LSP-Baru", exact: true, name: "Profile", component: Profile },
  {
    path: "/detail-profile",
    exact: true,
    name: "Profile",
    component: DetailProfile,
  },
  {
    path: "/detail-paket",
    exact: true,
    name: "Paket",
    component: DetailPaket,
  },
  {
    path: "/detail-menu",
    exact: true,
    name: "Profile",
    component: DetailMenu,
  },
  // {
  //   path: "/data-LSP",
  //   exact: true,
  //   name: "Profile",
  //   component: Pendaftaran,
  // },
  {
    path: "/data-LSP",
    exact: true,
    name: "Profile",
    component: LSP,
  },
  {
    path: "/data-LSP/:action",
    exact: true,
    name: "Profile",
    component: LSPCE,
  },
  // {
  //   path: "/add-pendaftaran",
  //   exact: true,
  //   name: "Profile",
  //   component: TambahPendaftaran,
  // },
  {
    path: "/paket",
    exact: true,
    name: "Paket",
    component: Paket,
  },

  // {
  //   path: "/add-variable",
  //   exact: true,
  //   name: "Variable",
  //   component: TambahVariable,
  // },
  {
    path: "/role",
    exact: true,
    name: "Role",
    component: Role,
  },
  {
    path: "/menu",
    exact: true,
    name: "Menu",
    component: Menu,
  },
  {
    path: "/akses-menu",
    exact: true,
    name: "Akses Menu",
    component: AksesMenu,
  },
  {
    path: "/akses-user",
    exact: true,
    name: "Akses User",
    component: AksesUser,
  },
  {
    path: "/pengelolaan-surat",
    exact: true,
    name: "Pengelolaan Surat",
    component: LettersCategory,
  },
  {
    path: "/variable-dokument",
    exact: true,
    name: "Variable Dokument",
    component: VariableDokument,
  },

  {
    path: "/edit-paket",
    exact: true,
    name: "Edit Paket",
    component: EditPaket,
  },
  {
    path: "/edit-menu",
    exact: true,
    name: "Edit Menu",
    component: EditMenu,
  },
  {
    path: "/add-akses-user",
    exact: true,
    name: "Add Akses User",
    component: AddAksesUser,
  },
  {
    path: "/edit-akses-user",
    exact: true,
    name: "Edit Akses User",
    component: EditAksesUser,
  },
  {
    path: "/tagihan",
    exact: true,
    name: "Tagihan",
    component: Tagihan,
  },
  {
    path: "/report",
    exact: true,
    name: "Laporan",
    component: Laporan,
  },
  {
    path: "/add-report",
    exact: true,
    name: "Tambah Laporan",
    component: TambahLaporan,
  },
  {
    path: "/detail-report",
    exact: true,
    name: "Detail Laporan",
    component: DetailLaporan,
  },
  {
    path: "/detail-akses-user",
    exact: true,
    name: "Akses User",
    component: DetailAksesUser,
  },
];

export default routes;
