import React from "react";
import FormLogin from "./Form";

import LoginContextContainer from "../../context/login";

function Login() {
  return (
    <LoginContextContainer>
      <FormLogin />
    </LoginContextContainer>
  );
}

export default Login;
