import React, { useContext } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Alert,
  Form,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
} from "reactstrap";
import { Input } from "antd";
import LoadingOverlay from "react-loading-overlay";
import "./style.css";
import "../../css/Button.css";
import "antd/dist/antd.css";
import { LoginContext } from "../../context/login";
import { Redirect } from "react-router-dom";
import Logo from "../../assets/image/nas_landscape.png";

function FormLogin(props) {
  const context = useContext(LoginContext);

  return (
    <LoadingOverlay active={context.loading} spinner text="Please Wait...">
      {localStorage.length == 0 ? (
        <Redirect to={"/login"} />
      ) : (
        <Redirect to={"/dashboard"} />
      )}
      <div className="app flex-row align-items-center animated fadeIn backgroundColor">
        <Modal>
          <ModalHeader>
            <b>Forgot Password</b>
          </ModalHeader>
          <ModalBody>
            <Row className="center-block" style={{ width: "75%" }}>
              <Col md="20">
                <Input
                  className="inputBox"
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Please input your email account"
                />
              </Col>
              <br />
            </Row>
            <Row className="center-block">
              <Col>
                <Button className="btn-submit">Submit</Button>
              </Col>
            </Row>
            <Modal>
              <ModalHeader>Confirmation Message</ModalHeader>
              <ModalBody>
                Your password has been reset,Please Check Your email
              </ModalBody>
              <ModalFooter>
                <Button color="secondary">OK</Button>
              </ModalFooter>
            </Modal>
          </ModalBody>
        </Modal>

        <Container>
          <Row className="justify-content-center">
            <div class="w-full max-w-xs">
              <form
                class="bg-white shadow-md rounded-md px-8 pt-6 pb-8 mb-4"
                onSubmit={context.handleOnSubmitBtnLogin}
              >
                {/* <Form
                class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
                onSubmit={context.handleOnValidation}
              > */}
                <img className="login" src={Logo} alt="" />
                <div class="mb-4">
                  <label
                    class="block text-gray-800 text-sm font-bold mb-2"
                    for="username"
                  >
                    Username
                  </label>
                  {/* <input
                    class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="username"
                    type="text"
                    placeholder="Username"
                    name="username_email"
                    onChange={context.handleChangeName}
                  /> */}
                  <Input
                    size="large"
                    name="username_email"
                    // placeholder="Email/username"
                    onChange={context.handleChangeName}
                  />
                </div>
                <div class="mb-6">
                  <label
                    class="block text-gray-800 text-sm font-bold mb-2"
                    for="password"
                  >
                    Password
                  </label>

                  <Input.Password
                    size="large"
                    name="password"
                    onKeyPress={(ev) => {
                      if (ev.key === "Enter") {
                        context.handleOnSubmitBtnLogin();
                      }
                    }}
                    placeholder="Password"
                    onChange={context.handleChangePassword}
                  />
                </div>

                <Alert color="danger" hidden={context.hidden}>
                  {context.message}
                </Alert>
                <div class="flex items-center justify-between">
                  <button
                    class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-28 rounded focus:outline-none focus:shadow-outline"
                    type="button"
                    onClick={context.handleOnSubmitBtnLogin}
                  >
                    <p class="text-center">Login</p>
                  </button>
                </div>
              </form>
              {/* </Form> */}
              <p class="text-center text-white text-xs">
                Copyright &copy; 2021,
                <a href="https://www.aplikasisertifikasi.com">NAS</a>. All
                rights reserved
              </p>
            </div>
          </Row>
        </Container>
      </div>
    </LoadingOverlay>
  );
}
export default FormLogin;
